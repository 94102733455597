import React, {useState} from "react";
import {Nav, NavButton, NavButtonActive, SubNav, SubNavButton, SubNavButtonActive} from "../styles/NavStyles";
import { useNavigate } from "react-router-dom";

const Navigation = () => {

    const [activeButton, setActiveButton] = useState('home');
    const navigate = useNavigate();

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        navigate(`/${buttonId}`);
    };

    return (
        <Nav>
            <NavButton
                onClick={() => handleButtonClick("home")}
                as={activeButton === "home" ? NavButtonActive : NavButton}
            >
                홈
            </NavButton>
            <NavButton
                onClick={() => handleButtonClick("customize")}
                as={activeButton === "customize" ? NavButtonActive : NavButton}
            >
                프로젝트 만들기
            </NavButton>
            <SubNav>
                <SubNavButton
                    onClick={() => handleButtonClick("upload")}
                    as={activeButton === "upload" ? SubNavButtonActive : SubNavButton}
                >
                    파일 업로드
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("jobs")}
                    as={activeButton === "jobs" ? SubNavButtonActive : SubNavButton}
                >
                    지난 작업
                </SubNavButton>
            </SubNav>
            <NavButton
                onClick={() => handleButtonClick("manage")}
                as={activeButton === "manage" ? NavButtonActive : NavButton}
            >
                프로젝트 관리
            </NavButton>
            <SubNav>
                <SubNavButton
                    onClick={() => handleButtonClick("apiKeys")}
                    as={activeButton === "apiKeys" ? SubNavButtonActive : SubNavButton}
                >
                    API 키
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("billing")}
                    as={activeButton === "billing" ? SubNavButtonActive : SubNavButton}
                >
                    결제정보
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("usage")}
                    as={activeButton === "usage" ? SubNavButtonActive : SubNavButton}
                >
                    Usage
                </SubNavButton>
            </SubNav>
            <NavButton
                onClick={() => handleButtonClick("learn")}
                as={activeButton === "learn" ? NavButtonActive : NavButton}
            >
                가이드
            </NavButton>
            <SubNav>
                <SubNavButton
                    onClick={() => handleButtonClick("exploreGuides")}
                    as={activeButton === "exploreGuides" ? SubNavButtonActive : SubNavButton}
                >
                    가이드 탐색
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("docs")}
                    as={activeButton === "docs" ? SubNavButtonActive : SubNavButton}
                >
                    가이드 문서
                </SubNavButton>
            </SubNav>
        </Nav>
    );
}

export default Navigation;
