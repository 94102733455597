import React, {useState, useRef} from 'react';
import {Form, Input, Spinner, Col, Row} from 'reactstrap';
import MenuBar from '../components/MenuBar';
import {useNavigate} from "react-router-dom";
import {MainContainer} from "../styles/styles";

const HomePage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // subtitle generation으로 이동
    const onClickSubtitleGeneration = () => {
        navigate('/upload');
    }

    // 제품 큐레이션으로 이동
    const onClickQuration = () => {
        navigate('/quration');
    }

    return (
        <MainContainer>
            <MenuBar />
            <div style={{

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}>
                <h1 style={{
                    fontSize: '60px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                }}>audion</h1>
                <h2 style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                }}>We're here to assist you with any audio-related service you need</h2>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100px',
                }}>
                    <input
                        style={{
                            width: '600px',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            outline: 'none',
                            fontSize: '16px',
                            marginBottom: '20px',
                            marginRight: '10px',
                        }}
                        type="text"
                        placeholder="제품 키워드, 기술 등을 입력해주세요."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                    style={{
                        width: '100px',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        outline: 'none',
                        fontSize: '16px',
                        marginBottom: '20px',
                        marginLeft: '10px',
                    }}>제품 검색</button>
                </div>
                <button
                    style={{
                        width: '200px',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        outline: 'none',
                        fontSize: '16px',
                        marginBottom: '20px'
                }} onClick={onClickQuration}>제품 큐레이션 받기</button>
                <button onClick={onClickSubtitleGeneration}>Subtitle Generation</button>
            </div>
        </MainContainer>
    );
}

export default HomePage;
