import React, { useState } from 'react';
import axios from 'axios';

const Signup = () => {
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    // 유효성 검사 함수들
    const checkNickname = (nickname) => {
        const valid = /^[a-zA-Z0-9_-]+$/.test(nickname);
        if (!valid) {
            setError("이름은 알파벳과 숫자만 포함해야 합니다. 특수문자는 '_'와 '-'만 허용됩니다.");
        }
        return valid;
    };

    const checkUsername = (username) => {
        const valid = username.includes("@");
        if (!valid) {
            setError("올바른 이메일 주소를 입력해주세요.");
        }
        return valid;
    };

    const checkPassword = (password) => {
        const valid = password.length >= 8 &&
            /\d/.test(password) &&
            /[a-zA-Z]/.test(password) &&
            /[^a-zA-Z0-9]/.test(password);

        if (!valid) {
            setError("비밀번호는 8자 이상이어야 하며, 숫자, 문자, 특수문자를 포함해야 합니다.");
        }
        return valid;
    };

    const checkRepeatPassword = (password, repeatPassword) => {
        const valid = password === repeatPassword;
        if (!valid) {
            setError("비밀번호가 일치하지 않습니다.");
        }
        return valid;
    };

    // 폼 제출 함수
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        // 모든 유효성 검사를 통과한 경우
        if (checkNickname(nickname) && checkUsername(username) && checkPassword(password) && checkRepeatPassword(password, repeatPassword)) {
            try {
                // 서버에 요청 보내기 (추후 서버 URL로 수정 필요)
                const response = await axios.post(`${BACKEND_URL}/signup`, {
                    nickname,
                    username,
                    affiliation,
                    password,
                });

                console.log(response.data);

                if (response.data.success) {
                    setSuccess(true);
                    setError(null);
                } else {
                    setError("회원가입에 실패했습니다. 다시 시도해주세요.");
                }
            } catch (error) {
                setError("서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
            }
        }
    };

    return (
        <div className="signup-container">
            <h1>회원가입</h1>
            {error && <div className="error-message">{error}</div>}
            {success ? (
                <div className="success-message">
                    <h2>회원가입에 성공했습니다!</h2>
                    <p>로그인해주세요.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>이름 *</label>
                        <input
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email 주소 *</label>
                        <input
                            type="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>소속 (선택 사항)</label>
                        <input
                            type="text"
                            value={affiliation}
                            onChange={(e) => setAffiliation(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Password *</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Repeat Password *</label>
                        <input
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default Signup;
