import styled from "styled-components";

export const Nav = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 196px;
    flex-shrink: 0;
    //background: #F9F9F9;
    padding: 16px 0;
`;

export const NavButton = styled.button`
    width: 196px;
    height: 37px; 
    padding: 8px 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border: none;
    display: inline-flex;
    background: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Pretendard', sans-serif;
    line-height: 21px;
`;

export const NavButtonActive = styled(NavButton)`
    background: #020202;
    border-radius: 8px;
    color: #FFFFFF;
`;

// nav 버튼 하위 메뉴
export const SubNav = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SubNavButton = styled.button`
    width: 196px;
    height: 37px;
    padding: 15px 45px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border: none;
    display: inline-flex;
    background: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Pretendard', sans-serif;
    line-height: 21px;
`;

export const SubNavButtonActive = styled(SubNavButton)`
    background: #020202;
    border-radius: 8px;
    color: #FFFFFF;
`;
