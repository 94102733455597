import axios from 'axios';

// 단계별 진행 상태 업데이트
export const handleSubmit = async (e, {
    file, youtubeUrl, setIsLoading, setProgress, BACKEND_URL,
    setSubtitle, parseText, setSummary, num_speakers
}) => {
    e.preventDefault();

    if (!file && !youtubeUrl) {
        alert('Please select a file or enter a YouTube URL.');
        return;
    }

    const formData = new FormData();
    let url = '';
    if (file) {
        formData.append('file', file);
        formData.append('call_type', 'uri');
        formData.append('uri', file.name);
        url = `${BACKEND_URL}/service`;
    } else if (youtubeUrl) {
        formData.append('uri', youtubeUrl);
        formData.append('call_type', 'url');
        url = `${BACKEND_URL}/serviceUrl`;
    }
    formData.append('service_name', 'SubtitleGeneration');
    formData.append('media_type', 'audio');
    formData.append('num_speakers', num_speakers);

    setIsLoading(true);
    setProgress({ step: 'upload', percent: 0 });

    try {
        // 첫 번째 요청 (파일 업로드 및 자막 데이터 받기)
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                const uploadProgress = Math.round((progressEvent.loaded * 25) / progressEvent.total);
                setProgress({ step: 'upload', percent: uploadProgress });
            },
            onDownloadProgress: progressEvent => {
                const downloadProgress = Math.round(25 + (progressEvent.loaded * 25) / progressEvent.total);
                setProgress(prev => ({
                    step: 'download',
                    percent: Math.max(prev.percent, downloadProgress)
                }));
            },
            withCredentials: true
        });

        console.log('Response:', response);

        const data = response.data;
        setSubtitle(data);
        const text = parseText(data.content);

        if (data.content && data.content.result && data.content.result.subtitle && text) {
            // 첫 번째 요청이 완료된 후 자막 데이터로 두 번째 요청 (요약 요청)
            const formData2 = new FormData();
            formData2.append('service_name', 'MllmSummary');
            formData2.append('text', '요약해 주세요. ### ' + text);
            formData2.append('mode', 'summary');

            try {
                console.log('Requesting summary...');
                // 두 번째 요청 (요약)
                const responseSummary = await axios.post(`${BACKEND_URL}/service`, formData2, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: progressEvent => {
                        if (progressEvent.loaded === progressEvent.total) return; // 추가된 조건
                        const uploadProgress = Math.round(50 + (progressEvent.loaded * 25) / progressEvent.total);
                        setProgress(prev => ({
                            step: 'summaryUpload',
                            percent: uploadProgress
                        }));
                    },
                    onDownloadProgress: progressEvent => {
                        if (progressEvent.loaded === progressEvent.total) return; // 추가된 조건
                        const downloadProgress = Math.round(75 + (progressEvent.loaded * 25) / progressEvent.total);
                        setProgress(prev => ({
                            step: 'summaryDownload',
                            percent: Math.max(prev.percent, downloadProgress)
                        }));
                    },
                    withCredentials: true
                });

                console.log('Response summary:', responseSummary);

                // 요약 결과를 저장
                const summaryData = responseSummary.data;
                setSummary(summaryData.content.text);

                setProgress({ step: 'complete', percent: 100 });

                console.log('api done')

            } catch (error) {
                console.error('Error generating summary:', error);
                setProgress({step: 'complete', percent: 100});
            }
        } else {
            console.error('Subtitle data not found:', data);
            // 자막 데이터가 없을 경우 진행 상태를 100으로 설정
            setProgress({ step: 'complete', percent: 100 });
        }

    } catch (error) {
        console.error('Error processing request:', error);
        alert('Error processing request. Please try again.');
        setProgress({ step: 'complete', percent: 100 });
    }
};
