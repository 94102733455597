import styled from 'styled-components';

export const TabMenu = styled.ul`
    color: #5A5A5A;
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    padding: 6px 12px;
    line-height: 20px;

    .submenu {
        width: calc(90% / 5);
        padding: 15px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        transition: 0.5s;
        font-weight: 400;
    }

    .focused {
        background-color: rgb(255, 255, 255);
        color: black;
        border-bottom: 2px solid black;
        font-weight: 600;
    }
`;

export const Desc = styled.div`
    text-align: center;
    max-height: 350px;
    overflow: auto;
    padding: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    background-color: white;
    font-family: Pretendard, sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #8E8E93;
`;

export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 350px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    background-color: white;
`;

export const ChatBox = styled.div`
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
`;

export const InputContainer = styled.div`
    display: flex;
    padding: 10px;
    border-top: 1px solid #dcdcdc;
`;

export const InputBox = styled.input`
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 300;
    color: #AEAEB2;
`;

export const SendButton = styled.button`
    margin-left: 10px;
    background: white;
    border: none;
    cursor: pointer;
`;

export const ChatMessage = styled.p`
    background-color: ${props => props.user ? '#DCF8C6' : '#e0e0e0'};
    padding: 10px;
    color: #8E8E93;
    font-size: 14px;
    border-radius: 10px;
    line-height: 21px;
    max-width: 70%; /* 메시지 박스의 최대 너비를 설정 */
    word-wrap: break-word; /* 긴 단어가 있을 경우 자동 줄바꿈 */
    overflow-wrap: break-word; /* 텍스트가 길면 줄바꿈 */
    white-space: pre-wrap; /* 연속된 공백도 유지하면서 줄바꿈 */
    align-self: ${props => props.user ? 'flex-end' : 'flex-start'};
`;
