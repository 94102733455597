import styled from 'styled-components';

export const QurationStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    min-height: 100vh;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
`;

export const QurationHeader = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
`;

export const NextButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    padding: 1rem 2rem;
    border: 2px solid #333;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #fff;
        color: #333;
    }
`;

export const QurationBody = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* 요소들이 줄을 넘어갈 때 자동으로 다음 줄로 이동하도록 설정 */
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
`;


export const OptionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    margin: 1rem;
    padding: 1rem 2rem;
    border: 2px solid #333;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: #333;
        color: #fff;
    }
`;
