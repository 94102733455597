import styled from 'styled-components';

export const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const TimelineHeader = styled.div`
    padding: 14px 0;
    display: flex;
    
    .edit{
        width: 74px;
        height: 36px;
        padding: 0 16px;
        border-radius: 8px;
        border: 1px #30B0C7 solid;
        gap: 6px;
        background: white;
        color: #30B0C7;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        margin-right: auto;
    }
`;

export const SearchTextContainer = styled.div`
    padding: 8px 16px;
    width: 193px;
    height: 36px;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #F8F9FA;
    color: #C7C7CC;
    font-size: 12px;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    margin-right: auto;
`;

export const SpeakerOptionContainer = styled.div`
    cursor: pointer;
    width: 117px;
    height: 36px;
    padding: 0 12px;
    background: #f8f9fa;
    color: #8e8e93;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-right: auto;
    position: relative; /* 드롭다운 메뉴를 위해 부모 컨테이너를 기준으로 위치 설정 */
`;

export const SearchText = styled.input`
    width: 100%;
    color: #020202;
    border: none;
    outline: none;
    background: #F8F9FA;
    padding-left: 8px;
`;

export const CardContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow: hidden; // 스크롤 제거

    .speaker-emotion {
    }

    p {
        width: 56px;
        height: 24px;
        margin-right: 8px;
        background: rgba(98, 93, 245, 0.08);
        border-radius: 6px;
        font-weight: 500;
        font-size: 12px;
        color: #625DF5;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .emotions {
        width: 45px;
    }
`;

export const Card = styled.div`
    padding: 12px 16px;
    width: 100%;
    border-radius: 10px;
    background-color: ${(props) =>
            props.score <= -0.8 ? '#FFC0CB' :    // 분홍색
            props.score <= -0.5 ? '#D3D3D3' :    // 회색
                    props.score <= -0.3 ? '#FFFFE0' :    // 노란색
                            '#F6FFDF'                      // 초록색
    };
    display: inline-flex; /* 기본적으로 flex로 설정 */
    flex-direction: column;
    margin: 10px 0; /* 위아래 여백 설정 */
    border: 1px rgba(222, 226, 230, 0.50) solid;
`;

export const CardText = styled.div`
    color: #020202;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    font-weight: 300;
    line-height: 21px;
`;

export const CardTime = styled.div`
    color: #6D6D6D;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    font-weight: 300;
    line-height: 21px;
    margin-top: 8px;
`;

export const PaginationButton = styled.button`
    color: black;
    background: white;
    height: 30px;
    border-radius: 0 6px 6px 0;
    padding: 0 9px;
    cursor: pointer;
    border: 1px rgba(222, 226, 230, 0.50) solid;
    
    &:disabled {
        color: #8E8E93;
        cursor: not-allowed;
    }
`;

