import React, {useState} from 'react';
import MenuBar from '../components/MenuBar';
import {
    ButtonContainer,
    NextButton,
    OptionButton,
    QurationBody,
    QurationHeader,
    QurationStyles
} from "../styles/QurationStyles";
import styled from 'styled-components';

// BackButton 스타일 정의
const BackButton = styled(NextButton)`
    background-color: #ccc;
    margin-right: 1rem;
`;

const QurationPage = () => {
    const [step, setStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSubOption, setSelectedSubOption] = useState(null);
    const [finalChoices, setFinalChoices] = useState([]);

    const randomOptions = [
        "자동으로 회의이 생성되면 좋겠어요",
        "음성 챗봇을 만들고 싶어요",
        "음원(사람 말소리와 관련이 없는 소리)과 관련된 작업이에요",
        "음원(노래) 및 영상과 관련된 작업이에요",
        "음성을 텍스트로 바꾸고 싶어요",
        "텍스트를 음성으로 바꾸고 싶어요",
        "음성에서 사용자 상태를 예측하고 싶어요",
        "잘 모르겠어요."
    ];

    const finalOptions = [
        "음성 인식", "음성 분리", "텍스트 생성"
    ];

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
    };

    const handleSelectSubOption = (option) => {
        setSelectedSubOption(option);
    };

    const handleFinalChoiceChange = (option) => {
        setFinalChoices((prevChoices) =>
            prevChoices.includes(option)
                ? prevChoices.filter(choice => choice !== option)
                : [...prevChoices, option]
        );
    };

    return (
        <div>
            <MenuBar />
            <QurationStyles>
                {step === 0 && (
                    <div>
                        <QurationHeader>안녕하세요. Audion에 오신 것을 환영합니다.</QurationHeader>
                        <NextButton onClick={handleNext}>Next</NextButton>
                    </div>
                )}

                {step === 1 && (
                    <div>
                        <QurationHeader>고객의 니즈에 따른 음성 AI 서비스를 제공해드립니다.</QurationHeader>
                        <NextButton onClick={handleNext}>Next</NextButton>
                    </div>
                )}

                {step === 2 && (
                    <div>
                        <QurationHeader>필요하신 상황 별 혹은 기능 별로 추천해드릴 수 있어요. 어떤 방식으로 추천을 받으실지 골라주세요.</QurationHeader>
                        <QurationBody>
                            <OptionButton
                                onClick={() => handleSelectOption("상황에 따른 추천")}
                                style={{
                                    borderColor: selectedOption === "상황에 따른 추천" ? 'lightblue' : 'initial'
                                }}
                            >
                                상황에 따른 추천
                            </OptionButton>
                            <OptionButton
                                onClick={() => handleSelectOption("기능에 따른 추천")}
                                style={{
                                    borderColor: selectedOption === "기능에 따른 추천" ? 'lightblue' : 'initial'
                                }}
                            >
                                기능에 따른 추천
                            </OptionButton>
                            <OptionButton
                                onClick={() => handleSelectOption("잘 모르겠어요")}
                                style={{
                                    borderColor: selectedOption === "잘 모르겠어요" ? 'lightblue' : 'initial'
                                }}
                            >
                                잘 모르겠어요
                            </OptionButton>
                        </QurationBody>
                        <ButtonContainer>
                        {selectedOption && <NextButton onClick={handleNext}>Next</NextButton>}
                        </ButtonContainer>
                        </div>
                )}

                {step === 3 && (
                    <div>
                        <QurationHeader>어떤 상황에서 서비스가 필요하신지 골라주세요 각 상황을 클릭하시면 구체적을 예시 상황을 확인하실 수 있습니다. (원하시는 서비스가 없다면 가장 가까운 것을 골라주세요)</QurationHeader>
                        <QurationBody>
                            {randomOptions.map((option, index) => (
                                <OptionButton
                                    key={index}
                                    onClick={() => handleSelectSubOption(option)}
                                    style={{
                                        borderColor: selectedSubOption === option ? 'lightblue' : 'initial'
                                    }}
                                >
                                    {option}
                                </OptionButton>
                            ))}
                        </QurationBody>
                        <ButtonContainer>
                        <NextButton onClick={handleBack}>Back</NextButton>{selectedSubOption && <NextButton onClick={handleNext}>Next</NextButton>}
                            </ButtonContainer>
                        </div>
                )}

                {step === 4 && (
                    <div>
                        <QurationHeader>회의록과 관련된 기능들은 아래와 같아요.
                            이 중 어떤 기능을 사용하고 싶으신가요? (복수 선택 가능)</QurationHeader>
                        <QurationBody>
                            {finalOptions.map((option, index) => (
                                <OptionButton
                                    key={index}
                                    onClick={() => handleFinalChoiceChange(option)}
                                    style={{
                                        borderColor: finalChoices.includes(option) ? 'lightblue' : 'initial'
                                    }}
                                >
                                    {option}
                                </OptionButton>
                            ))}
                        </QurationBody>
                        <ButtonContainer>
                        <NextButton onClick={handleBack}>Back</NextButton>
                        {finalChoices.length > 0 && <NextButton onClick={handleNext}>Next</NextButton>}
                            </ButtonContainer>
                    </div>
                )}

                {step === 5 && (
                    <div>
                        <QurationHeader>선택하신 내용:</QurationHeader>
                        <ul>
                            <li>첫 번째 선택: {selectedOption}</li>
                            <li>두 번째 선택: {selectedSubOption}</li>
                            <li>마지막 선택: {finalChoices.join(', ')}</li>
                        </ul>
                        <ButtonContainer>
                        <NextButton onClick={handleBack}>Back</NextButton>
                            </ButtonContainer>
                        </div>
                )}
            </QurationStyles>
        </div>
    );
}

export default QurationPage;
