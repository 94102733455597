import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

// DropDown Container
export const DropDownContainer = styled.div`
  width: 100%;
`;

// DropDown Button
export const DropDownButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #F8F9FA;
    border: none;
    color: #8E8E93;
    cursor: pointer;
    border-radius: 6px;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 12px;
`;

export const Arrow = styled.span`
    margin-left: 8px;
    border: solid #8e8e93;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};
    transition: transform 0.3s ease;
`;


// DropDown Menu
export const DropDownMenu = styled.ul`
    background: #fff;
    border-radius: 8px;
    position: absolute;
    top: 35px; // Adjusted based on button height
    right: 0;
    width: 100px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;

    ${(props) =>
            props.$isOpen &&
            css`
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            `}
`;

// Menu Item
export const MenuItem = styled.li`
  list-style: none;
  margin: 5px 0;
`;

// Styled Link
export const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
    font-family: Pretendard, sans-serif;
    font-size: 12px;
    font-weight: 400;

    &:hover {
        color: #007bff; // Change color on hover if needed
    }
`;
