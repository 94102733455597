// src/apis/youtubeData.js

const API_KEY = 'AIzaSyDb1ZurlaYLUJ4SnQ58SP5YLBzTxALRGSc'; // Replace with your YouTube Data API key

// Function to extract the video ID from the YouTube URL
export const extractVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
};

// Function to fetch video info from YouTube API
export const fetchVideoInfo = async (youtubeUrl, setVideoTitle, setThumbnailUrl) => {
    const videoId = extractVideoId(youtubeUrl);

    if (videoId) {
        try {
            const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`);
            const data = await response.json();

            if (data.items && data.items.length > 0) {
                const videoInfo = data.items[0].snippet;
                setVideoTitle(videoInfo.title);
                setThumbnailUrl(videoInfo.thumbnails.high.url);
            }
        } catch (error) {
            console.error('Error fetching video info:', error);
        }
    } else {
        alert('유효한 YouTube URL을 입력해주세요.');
    }
};
