import { useRef, useState } from "react";
import useDetectClose from "../hooks/useDetectClose";
import { DropDownButton, DropDownContainer, DropDownMenu, MenuItem, Arrow } from "../styles/DropDownStyled";

const DropDown = ({ options, selectedOption, onSelect }) => {
    const dropDownRef = useRef(null);
    const [isOpen, setIsOpen] = useDetectClose(dropDownRef, false);

    const handleOptionClick = (option) => {
        onSelect(option); // 선택된 옵션을 상위 컴포넌트로 전달
        setIsOpen(false); // 옵션 클릭 시 드롭다운 닫기
    };

    return (
        <DropDownContainer>
            <DropDownButton onClick={() => setIsOpen(!isOpen)}>
                {selectedOption}
                <Arrow $isOpen={isOpen} />
            </DropDownButton>
            <DropDownMenu ref={dropDownRef} $isOpen={isOpen}>
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleOptionClick(option)}>
                        {option}
                    </MenuItem>
                ))}
            </DropDownMenu>
        </DropDownContainer>
    );
};

export default DropDown;
